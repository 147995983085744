import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css, createGlobalStyle } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'

import { Layout, Slider, Footer, FullPage, ToolsSlider } from '../components'

import { container, padding, linedText, bgImage, colours, type, wrapper, buttonStyles, textOffset, pagePaddingTop } from '../styles/global'
import { parseACF } from '../utils/utils'
import { media, useBreakpoint } from '../styles/utils'
import { FULLPAGE_KEY } from '../constants';

const ServicesTools = (props) => {
    const data = parseACF(useStaticQuery(query), 'allWordpressInfopages')
    const [fullPage, setFullPage] = useState(false)
    const [activePageSection, setActivePageSection] = useState(0)
    const isPhone = useBreakpoint('phone')

    const beforeSlideChange = (origin, destination, direction) => {        
        setActivePageSection(destination.index)
    } 

	const renderHero = () => {
		const { title, background_image, intro_text } = data;

		return (
            <Hero
                className={'section fp-auto-height'}
            >
                <Container>
                    <Heading as="h1">{title}</Heading>

                    <LinedText>
                        <Description
                            dangerouslySetInnerHTML={{ __html: intro_text }}
                        />
                    </LinedText>

                    <ToolsLink 
                        onClick={() => fullPage?.moveSectionDown()}
                    >
                        + Explore Services
                    </ToolsLink>
                    <ToolsLink 
                        onClick={() => fullPage?.moveTo(fullPage.sectionCount - 1)}
                    >
                        + Explore Tools
                    </ToolsLink>
                </Container>

                {background_image && (
                    <Image
                        key={background_image?.sizes?.full_width}
                        src={background_image?.sizes?.full_width}
                    >
                        {(src, loading) => {
                            return (
                                <BGImage
                                    image={src}
                                    style={{ opacity: loading ? 0 : 1 }}
                                />
                            )
                        }}
                    </Image>
                )}
            </Hero>
        )
    }
    
    const renderSlider = (item) => {
        const [activeIndex, updateActiveIndex] = useState(0)

        const renderSlides = (slides) => {
            return slides.map((slide, i) => {
                const image = slide?.image || slide?.hero_image
                if (!image) return

                return (
                    <Slide key={i}>
                        <SlideImage image={image?.sizes?.medium2} />
                    </Slide>
                )
            })
        }


        const slideItems = item?.project_slider_override?.length  ? item.project_slider_override : item?.project_slider;
        const slides = item?.project_slider_override?.length ? slideItems : slideItems.map(i => i.acf)

        return (
            <>
                <Slider
                    slides={slides}
                    sliderStyles={sliderStyles}
                    renderSlides={renderSlides}
                    sliderParams={{
                        effect: 'fade',
                        speed: 750,
                        autoplay: {
                            delay: 5000,
                            disableOnInteraction: false,
                        },
                    }}
                    onSlideChange={(index) => updateActiveIndex(index)}
                    hoverCursors={slides?.length > 1}
                    counter={true}
                />

                <SliderCaptions>
                    {slideItems.map((slide, i) => {
                        const { slug, title, acf } = slide

                        return (
                            <SliderCaption 
                                key={slug} 
                                active={i === activeIndex}
                                to={`/case-studies/${slug}`}
                            >
                                {acf && (
                                    <>
                                        <SlideTitle>{title}. </SlideTitle>
                                        <div>{acf?.location}</div>
                                    </>
                                )}

                                {!acf && slide.use_caption && (
                                    <SlideTitle
                                        dangerouslySetInnerHTML={{__html: slide.caption}}
                                    />
                                )}
                            </SliderCaption>
                        )
                    })}
                </SliderCaptions>
            </>
        )
    }
	
	const renderService = (item, i) => {
		if (!item) return

		item = {
			...item,
			...item.acf
        }

		return (
            <Container 
                key={item.title}
                className={'section'}
            >
                <Service>
                    <ServiceRow>
                        <ServiceColumn isHeading>
                            <HeadingIncrementer>{String.fromCharCode(65 + i)}</HeadingIncrementer>
                            <Heading>
                                {item.title}                                
                            </Heading>
                        </ServiceColumn>
                        <ServiceColumn>
                            {renderSlider(item)}
                        </ServiceColumn>
                    </ServiceRow>

                    <ServiceRow>
                        <ServiceColumn>
                            <Subheading>{item.sub_heading}</Subheading>
                            <ParagraphIndent
                                dangerouslySetInnerHTML={{ __html: item.text }}
                            />
                        </ServiceColumn>
                        <ServiceColumn>
                            <Aside>
                                <Subheading isListSubheading>Services include:</Subheading>
                                <ul>
                                    {item.services_include.map(({ item }) => (
                                        <li key={item}>{item}</li>
                                    ))}
                                </ul>
                            </Aside>
                        </ServiceColumn>
                    </ServiceRow>
                </Service>
            </Container>
        )
	}

	const renderServices = () => {
		if (!data.services) return

		return data.services.map((item, i) => {
			return renderService(item, i)
		})
    }
    
    const renderTools = () => {
        return (
            <ToolsSlider data={data} />
        )
    }
    
	return (
		<Layout	
            meta={data && data.seo}
            logoTheme={activePageSection >= (fullPage.sectionCount - 2) ? 'light' : 'dark'}
            hideByLine={activePageSection >= 1}
            disableFooter
        >
			<Wrapper>
                <FullPage
                    onApiChange={setFullPage}
                    beforeSlideChange={beforeSlideChange}
                >
                    {renderHero()}
                    {renderServices()}
                    {renderTools()}

                    <Footer
                        className={'section fp-auto-height'}
                    />
                </FullPage>                              
			</Wrapper>
		</Layout>	
	)
}

// Shared

const Heading = styled.h2`
    ${type.heading2};
    color: #000;
`

const HeadingIncrementer = styled.div`
    font-size: 100px;
    color: ${colours.darkRed};
    padding-top: 68px;
    padding-bottom: 10px;

    ${media.phone`
        padding-top: 0;
        padding-bottom: 0;
        line-height: 1em;
    `}
`

const Subheading = styled.h3`
    color: #000;
    font-size: 20px;
    margin-bottom: 20px;

    ${media.desktopSmall`
        font-size: 16px;
    `}

    ${media.phone`
        margin-bottom: 0;
        font-size: 18px;
    `}

    ${props => {
        if (props.isListSubheading) return css`
            margin-bottom: 5px;
        `
    }}
`

const Description = styled.div``
const Info = styled.div``

const ToolsLink = styled.div`
    ${buttonStyles};
    cursor: pointer;
    margin-right: 60px;
    
    ${media.phone`
        margin-right: 20px;
    `}
`


const Image = styled(ProgressiveImage)`
	overflow: hidden;
`

const BGImage = styled.div`
	background-image: url(${props => props.image});  
	${bgImage};
	transition: opacity 0.45s ease;
    background-position: bottom left;
`

const LinedText = styled.div`
	${linedText}
`

// Layout

const Wrapper = styled.div`
    ${wrapper}
    width: 100%;
`

const Container = styled.div`
    ${container}
    ${padding}
    width: 100%;

    .counter-wrapper {
        position: absolute;
        display: flex;
        justify-content: flex-end;
        margin-top: 15px;
        padding: 0;

        ${media.phone`
            margin-top: 17px;    
            align-items: center;
        `}
    }

    .counter {
        position: static;
        justify-content: flex-end;
        height: inherit;

        > * {
            font-size: 16px;

            &:first-child {
                color: ${colours.darkGray};
                visibility: visible;
            }

            &:nth-child(2) {
                &, span {
                    color: ${colours.darkRed}
                }
            }
        }
    }
`

// Hero

const Hero = styled.div`
    ${pagePaddingTop}
    padding-bottom: 325px;
    width: 100%;
    background: ${colours.offWhite};
    position: relative;
    height: 100vh;
    min-height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;

    ${BGImage} {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
        max-width: 1380px;
        mix-blend-mode: multiply;
        pointer-events: none;
        height: 1080px;
    }

    ${Container} {
        ${LinedText} {
            margin-bottom: 20px;
            max-width: 700px;
            margin-bottom: 60px;

            > * {
                color: ${colours.darkRed};
            }
        }
    }

    ${media.phone`
        padding-bottom: 0;
        height: 100vh;
    `}
`

// Service Item

const Service = styled.div`
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    padding-top: 100px;
    padding-bottom: 40px;
    /* max-height: 1200px; */

	${Heading} {
		${type.heading2}
		color: ${colours.darkRed}
	}

    ${media.phone`
        height: inherit;
        padding-top: 0;
        padding-bottom: 20px;
    `}
`

const ServiceRow = styled.div`
    display: flex;
    margin-bottom: 50px;

    ${media.phone`
        flex-direction: column;
        margin-bottom: 10px;

        /* on mobile only, swap the order of the top left and top right sections */
        &:first-child > div:first-child {
            order: 1;
        }
    `}

    > div {
        width: 50%;
        margin-right: 40px;
        
        &:last-child {
            margin-right: 0;
        }

        ${media.phone`
            margin-right: 0;
            margin-bottom: 40px;
            width: 100%;
            overflow: visible;

            &:nth-child(1) {
                margin-bottom: 25px;
            }
        `}
    }

    &:nth-child(2) {
        margin-top: 140px;

        @media (max-height: 1000px) {
            margin-top: 32px;
        }
    }
`

const ServiceColumn = styled.div`

    ${Heading} {
        line-height: 1.2em;
    }

    ${Subheading} {
        margin-bottom: 5px;
    }
    
    ${props => {
        if (props.isHeading) return css`
            margin-bottom: 10px;
            align-self: flex-end;

        `
    }}

    ${media.phone`
        ${Heading} {
            max-width: 215px;
            line-height: 40px;
        }

        ${Subheading} {
            margin-bottom: 5px;
        }
    `}
`

const ParagraphIndent = styled.p`
    ${textOffset};
    color: ${colours.midGray};

    margin-top: 0;

    ${media.phone`
        margin-top: 0;
    `}

    p {
        margin-bottom: 0;
        margin-top: 0;
    }
`

const SliderCaptions = styled.div`
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    margin-top: 20px;

    ${media.desktopSmall`
        font-size: 14px;
    `}

    ${media.phone`
        font-size: 14px;
        margin-bottom: 30px;
    `}
`

const SliderCaption = styled(Link)`
    opacity: ${(props) => (props.active ? '1' : '0.25')};
    transition: opacity 300ms ease-in-out;
    margin-right: auto;
    padding-right: 20px;
    cursor: pointer;
    font-size: 16px;
    line-height: 1em;
    color: ${colours.darkRed};

    position: absolute;
    display: flex;
    opacity: ${(props) => (props.active ? '1' : '0')};

    ${media.desktopSmall`
        font-size: 14px;
        max-width: 80vw;
    `}

    ${media.phone`
        color: ${colours.midGray};
        display: inline;

        > *,
        > * > * {
            display: inline;
            line-height: 1.3em;
        }
    `}
`

const SlideTitle = styled.div`
    margin-bottom: 10px;
    margin-right: 0.3em;

    ${media.phone`
        margin-right: 10px;
        color: ${colours.darkGray};
    `}
`

const sliderStyles = css`
    ${media.phone`
        width: 100vw;
        transform: translateX(-20px);
    `}
`

const SlideImage = styled.div`
    background-image: url(${(props) => props.image});
    ${bgImage};
    transition: opacity 0.45s ease;
`

const Slide = styled.div`
    padding-bottom: 57.89%;
    background-color: ${colours.lightGray};
    width: 100% !important;

    ${SlideImage} {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
`

const Aside = styled.aside`
    max-width: 520px;
    flex: 1;
    font-size: 20px;
    color: ${colours.midGray};
    margin-left: auto;

    ${media.desktopSmall`
        font-size: 16px;
    `}

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        line-height: 1.5;
        margin-bottom: 40px;
    }

    li {
        color: ${colours.darkRed};
        border-bottom: 1px solid currentColor;
        padding-left: 25px;
        position: relative;

        &::before {
            content: '—';
            position: absolute;
            left: 0;
            top: 0;
        }
    }

    ${media.phone`
        font-size: 18px;
        line-height: 2;
        margin-left: inherit;
    `}
`


export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "services-tools" } }) {
            nodes {
				title
                acf_json
            }
		}
    }
`

export default ServicesTools
